.vodacom-logo{
    text-align: center;
    width: 100%;
    height: 11vw;
    margin-left: -5.5vw;
    position: absolute;
    z-index: 1;
    top: 48vw;
}

.slidftxt-secondary{
    margin-top: 20px;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    max-width: 600px;
    position: absolute;
    top: 79vw;
    color: #fff;
}
.customPlanSettings{
    top: 118vw !important;
}

.feature {
    display: flex;
    align-items: center;
    gap: 10px;
}

#plansCustom{
    width:100% !important
}
.plansWrapper{
    display: flex !important;
    flex-direction: column;
}

.checkmark {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.bxplanSecondary{
    width: 94%;
    height: 19vw;
    border: 1px solid #fffFFF33;
    border-radius: 2vw;
    margin-bottom: 7%;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    float: left;
}

.ruppetxt-secondary{
    color: #ffffff;
    margin-top: 2vw;
    width: fit-content;
    position: absolute;
    right: 5px;
    font-size: 12px;
}

.checkmark-secondary{
    left:5px;
    margin-top: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.typeplanSecondary{
    width: fit-content;
    padding: 4px;
    border-radius: 10px 0px;
    color: #fff !important;
    background-image: linear-gradient(68deg, rgb(226, 1, 1) 24%, rgb(138, 1, 1) 84%);
    font-size: 12px !important;
}
.planName{
    color: #fff;
    position: absolute;
    left: 40px;
    width: max-content;
    font-size: 12px !important;
}
.hideHeader{
    background: transparent !important;
    padding: 0px !important;
}
.posslidconSecondary{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:75px
}
.vodacomLogoDesktop{
    width:250px !important;
    text-align: center;
}
.features-grid-desktop{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    max-width: 600px;
    top: 79vw;
    color: #fff;
}
.checkmark-desktop{
    width: 30px;
    height: 30px;
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.planrightsecSecondary{
    right:25% !important;
    top:19rem !important
}
.bxplanSecondaryDesktop{
    float: left;
    width: 100%;
    margin: 10px auto;
    position: relative;
    opacity: .8;
    border-radius: 9px;
    /* -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px); */
    border: 1px solid rgba(255, 255, 255, .2);
}

.bxplanSecondaryDesktop .paddpan{
    width: 88%;
    padding: 0px !important;
    height: 65px;
}

.checkmark-secondary-desktop{
    left:5px;
    margin-top: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px !important;
    width: 21px !important;
}
.ruppetxt-secondary-desktop{
    width: fit-content;
    transform: translate(32px, -16px);
    float: right;
    font-size: 12px !important;
    margin-top: 2vw !important;
}
.planname-desktop{
    margin-left: 37px;
    width: -webkit-fill-available;
    white-space: nowrap;
    color: #fff;
    font-size: 16px;
}
.logo-toggle-desktop{
    padding-left:30px
}
